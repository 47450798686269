import styled from "styled-components";

export const ContainerFaq = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: red; */

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;

    .title-img {
      width: 100%;
      min-width: 20rem;
      max-width: 30rem;

      img {
        width: 100%;
      }
    }
    .title {
      h2 {
        font-size: 2rem;
        text-align: center;
        color: #002c5b;
        margin: 2rem 0 1rem 0;
      }
    }

    @media only screen and (min-width: 990px) {
      .title {
        h2 {
          font-size: 2.5rem;
        }
      }
    }
  }

  .container-questions {
    padding: 1.5rem 1.6rem 1.5rem 2.2rem;
    max-width: 60rem;
  }
`;
