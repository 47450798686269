import { Dispatch, SetStateAction, useState } from "react";
import { Menu, Logo, BtnMenu } from "./style/Menu";
import Lottie from "react-lottie";
import logo from "./animations/logo-partner.json";
import { Mobile, BgBlack, NavLinks } from "./style/Mobile";
import { FaYoutube } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { IoSchoolSharp } from "react-icons/io5";

const NavBar = (props: {
  isMobile: boolean;
  setIsMobile: Dispatch<SetStateAction<boolean>>;
  selected: string;
  setSelect: Dispatch<SetStateAction<string>>;
}) => {
  const [navBar, setNavBar] = useState(false);
  // eslint-disable-next-line
  const [stop, setStop] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };

  const logoAnimation = {
    loop: false,
    autoplay: true,
    animationData: logo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <Menu navBar={navBar}>
        <div className="desktop-container w-1000">
          <Logo to="home">
            <Lottie
              options={logoAnimation}
              isStopped={stop}
              style={{
                pointerEvents: "none",
                maxWidth: 400,
                height: "auto",
              }}
            />
          </Logo>
          <div
            className="btn-menu-bg"
            style={{
              position: "absolute",
              height: "100%",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 5,
            }}
          >
            <BtnMenu
              isMobile={props.isMobile}
              onClick={() => props.setIsMobile(!props.isMobile)}
            ></BtnMenu>
          </div>
          <BgBlack isMobile={props.isMobile} />
          <Mobile isMobile={props.isMobile}>
            <div className="items">
              <ul>
                <li>
                  <NavLinks
                    onClick={() => {
                      props.setSelect("good");
                      props.setIsMobile(false);
                    }}
                    className={props.selected === "good" ? "active" : ""}
                    to="good"
                    smooth={true}
                    offset={-170}
                    duration={50}
                  >
                    Benefícios
                  </NavLinks>
                </li>
                <li>
                  <NavLinks
                    onClick={() => {
                      props.setSelect("about");
                      props.setIsMobile(false);
                    }}
                    className={props.selected === "about" ? "active" : ""}
                    to="about"
                    smooth={true}
                    offset={-20}
                    duration={50}
                  >
                    Sobre
                  </NavLinks>
                </li>
                <li>
                  <NavLinks
                    onClick={() => {
                      props.setSelect("faq");
                      props.setIsMobile(false);
                    }}
                    className={props.selected === "faq" ? "active" : ""}
                    to="faq"
                    smooth={true}
                    offset={-20}
                    duration={50}
                  >
                    Dúvidas
                  </NavLinks>
                </li>
              </ul>
            </div>
            <hr className="line" />
            <div className="social">
              <div className="login">
                <a
                  href="https://sejaparceiro.mobiltracker.com.br/"
                  target="blank"
                >
                  Seja parceiro
                </a>
                <a
                  href="https://portalparceiros.mobiltracker.com.br/login"
                  target="blank"
                >
                  Faça Login
                </a>
              </div>
              <div className="icons">
                <ul>
                  <li>
                    <a
                      target="blank"
                      href="https://www.youtube.com/user/Mobiltracker"
                    >
                      <FaYoutube className="single-icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://api.whatsapp.com/send?phone=555182819595&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20a%20respeito%20do%20*mobilParceiros*"
                    >
                      <RiWhatsappFill className="single-icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://www.academiadorastreamento.com.br/"
                    >
                      <IoSchoolSharp className="single-icon" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Mobile>
        </div>
      </Menu>
    </>
  );
};

export default NavBar;
