import styled from "styled-components";

export const ContainerHeader = styled.div`
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(120deg, #002c5b, #329e66 90%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Wave = styled.div`
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: auto;
  max-height: 15rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.1rem;

  .animation-arrow {
    position: absolute;
    top: 15%;
    right: 50%;
    transform: translateX(50%);
    width: 15%;
    max-width: 5rem;
    cursor: pointer;
  }

  @media only screen and (min-width: 990px) {
    .animation-arrow {
      top: 35%;
    }
  }
  @media only screen and (min-width: 1700px) {
    .animation-arrow {
      top: 55%;
    }
  }
  @media only screen and (max-height: 550px) {
    .img-hidden {
      visibility: hidden;
    }
    .animation-arrow {
      top: 70%;
      background-color: rgba(255, 255, 255);
      border-radius: 50%;
      max-width: 3rem;
    }
  }
`;

export const ContentText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  transition: all 1s ease-in-out;

  .animation-main {
    width: 100%;
    height: 100%;
    align-self: flex-end;
    max-width: 25rem;
    max-height: 25rem;
    position: absolute;
    bottom: -18%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .containerTextBoxP {
    margin-top: 8rem;
    z-index: 2;
    height: 100%;
    max-height: 20rem;
    width: 100%;
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    backdrop-filter: blur(0.1rem);

    .textBoxP {
      width: 100%;
      display: flex;
      flex-direction: column;
      color: #fff;

      h1 {
        font-size: 1.5rem;
        font-weight: 500;
      }

      .small {
        line-height: 1.5rem;
        font-size: 1rem;
        font-weight: 300;

        &:nth-child(4) {
          margin-top: 1.2rem;
        }
      }

      h1,
      .small {
        margin-left: 2rem;
      }

      @media only screen and (min-width: 990px) {
        h1 {
          font-size: 1.8rem;
        }

        .small {
          font-size: 1.2rem;
        }
      }
    }
    .btn {
      margin-left: 1rem;
      margin-top: 2rem;
      width: 15rem;
    }
  }

  @media only screen and (min-width: 990px) {
    justify-content: center;

    .animation-main {
      top: 60%;
      max-width: 40rem;
      max-height: 40rem;
    }
    .containerTextBoxP {
      margin-top: 0;
      background: transparent;
      backdrop-filter: initial;
      max-width: 30rem;
      top: 10rem;
      z-index: 1;
      max-height: 22rem;
    }
  }
  @media only screen and (max-height: 510px) {
    .containerTextBoxP {
      backdrop-filter: blur(0rem);
    }
  }
`;
