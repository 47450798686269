import { useState } from "react";
import NavBar from "./components/Menu";
import Header from "./components/Header";
import Company from "./components/Company";
import Faq from "./components/Faq";
import WhatsAppFloat from "./components/WhatsAppFloat";
import Footer from "./components/Footer";
import GlobalStyle from "./globalStyles";
import { BrowserRouter as Router } from "react-router-dom";
import Advantage from "./components/card/Advantage";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelect] = useState("");

  return (
    <Router>
      <GlobalStyle />
      <NavBar
        selected={selected}
        setSelect={setSelect}
        isMobile={isMobile}
        setIsMobile={setIsMobile}
      />
      <WhatsAppFloat isMobile={isMobile} />
      <Header />
      <Advantage />
      <Company />
      <Faq />
      <Footer />
    </Router>
  );
}

export default App;
