import textimg1 from "../img/text-img-01.svg";
import textimg2 from "../img/text-img-02.svg";
import logo from "../img/logo-horizontal.svg";
import CallToAction from "./CallToAction";
import { Container } from "./style/Company";
import TabArrow from "./TabArrow";
import tab from "../img/tab-02.svg";
import { Link } from "react-scroll";

const Company = () => {
  return (
    <>
      <Container id="about">
        <Link to="about">
          <TabArrow image={tab} />
        </Link>
        <main className="about w-1000">
          <div className="title">
            <img src={logo} alt="" />
            <hr />
          </div>
          <div className="text-plus-image">
            <div className="img-about">
              <img
                src={textimg1}
                alt="imagem para ilustrar o rastreamento veicular"
              />
            </div>
            <div className="text">
              <p>
                A Mobiltracker Parceiros é uma solução para Centrais de
                Rastreamento que conta com um sistema robusto e em constante
                atualização. Auxiliamos você na criação e no gerenciamento de
                sua própria central, além de oferecermos treinamento gratuito e
                suporte especializado para garantir sua adaptação à nossa
                plataforma.
              </p>
              <p>
                Desde o começo, procuramos trabalhar de perto com nossos
                parceiros e seus clientes para entender suas necessidades, e o
                resultado é uma plataforma intuitiva e acessível, além de contar
                com diversas funcionalidades para tornar ainda melhor a
                experiência do usuário.
              </p>
            </div>
          </div>
          <div className="text-plus-image">
            <div className="img-about change-order-img">
              <img
                src={textimg2}
                alt="imagem para ilustrar o rastreamento veicular"
              />
            </div>
            <div className="text change-order-text">
              <p>
                Como se não bastasse, ainda temos a opção de personalização de
                aplicativo e portal web, que permite que você crie uma central
                com a sua marca e seu próprio app na Play Store, tornando o seu
                aplicativo de rastreamento de fácil acesso para seus clientes.
              </p>
              <p>
                Agora que você já conhece um pouco mais sobre a nossa plataforma
                de parceiros e suas vantagens, o que está esperando para entrar
                em contato conosco? Venha fazer parte da mais bem avaliada
                plataforma de rastreamento do mercado.
              </p>
            </div>
          </div>
          <CallToAction
            text="Seja Parceiro"
            className="hover-button-color"
            link="https://sejaparceiro.mobiltracker.com.br/"
          />
        </main>
      </Container>
    </>
  );
};

export default Company;
