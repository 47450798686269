import React from "react";
import Lottie from "react-lottie";
import { ContainerFooter } from "./style/Footer";
import waveFooter from "../img/wave3-01.svg";
import { ImYoutube } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { FaFacebookSquare } from "react-icons/fa";
import locationAnimation from "./animations/location.json";

const location = {
  loop: true,
  autoplay: true,
  animationData: locationAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Footer = () => {
  return (
    <ContainerFooter>
      <div className="wave-footer">
        <img
          src={waveFooter}
          alt="ilustração de onda para sinalizar o fim da página"
        />
      </div>
      <div className="animation-location">
        <Lottie options={location} height={"100%"} width={"100%"} />
      </div>

      <div className="content w-1000">
        <div className="external-links">
          <div className="links">
            <a target="blank" href="https://forum.mobiltracker.com.br/">
              Forum
            </a>
            <a target="blank" href="https://www.mobiltracker.com.br/blog">
              Blog
            </a>
            <a target="blank" href="https://www.academiadorastreamento.com.br/">
              Academia do Rastreamento
            </a>
            <a
              target="blank"
              href="https://api.whatsapp.com/send?phone=555182819595&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20a%20respeito%20do%20*mobilParceiros*"
            >
              Contato
            </a>
          </div>
          <div className="social-medias">
            <a
              target="blank"
              className="icone-social-media"
              href="https://www.facebook.com/Mobiltracker/"
            >
              <FaFacebookSquare />
            </a>
            <a
              target="blank"
              className="icone-social-media"
              href="https://www.youtube.com/user/Mobiltracker"
            >
              <ImYoutube />
            </a>
            <a
              target="blank"
              className="icone-social-media"
              href="mailto:parceiros@mobiltracker.com.br"
            >
              <MdEmail />
            </a>
            <a
              target="blank"
              className="icone-social-media"
              href="https://api.whatsapp.com/send?phone=555182819595&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20a%20respeito%20do%20*mobilParceiros*"
            >
              <RiWhatsappFill />
            </a>
          </div>
        </div>

        <hr />
        <div className="powered">
          <p>
            Powered by mobil<span>Parceiros</span>
          </p>
        </div>
      </div>
    </ContainerFooter>
  );
};

export default Footer;
