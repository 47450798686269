import styled from "styled-components";

type TabArrowProps = {
  image: string;
};

const Tab = styled.div`
  margin: 0 auto;
  height: 2rem;
  width: 5rem;
  position: relative;
  cursor: pointer;

  .img-tab {
    position: absolute;
    top: -0.5rem;
    height: 100%;
    width: 100%;
  }

  @media only screen and (min-width: 990px) {
    width: 8rem;
  }
`;

const TabArrow = (props: TabArrowProps) => {
  return (
    <Tab>
      <img
        className="img-tab"
        src={props.image}
        alt="ilustração de seta para sinalizar que a página tem continuação"
      />
    </Tab>
  );
};

export default TabArrow;
