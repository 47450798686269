import { useState } from "react";
import { ContainerFaq } from "./style/Faq";
import TabArrow from "./TabArrow";
import tabBlue from "../img/tab-03.svg";
import Question from "./Question";
import { DataFaq } from "./DataFaq";
import faq from "../img/faq-01.svg";
import { Link } from "react-scroll";

const Faq = () => {
  const [open, setOpen] = useState<null | number>(null);
  return (
    <ContainerFaq id="faq">
      <Link to="faq">
        <TabArrow image={tabBlue} />
      </Link>

      <div className="title-container">
        <div className="title-img">
          <img src={faq} alt="imagem ilustrativa para perguntas frequentes" />
        </div>

        <div className="title">
          <h2>Perguntas Frequentes</h2>
        </div>
      </div>

      <div className="container-questions w-1000">
        {DataFaq.map((info, index) => {
          const isOpen = open === index;
          if (index % 2 === 0) {
            return (
              <Question
                className="gray"
                index={index}
                open={isOpen}
                setOpen={() => {
                  setOpen(isOpen ? null : index);
                }}
                title={info.title}
                text={info.text}
                secondText={info.secondText}
                thirdText={info.thirdText}
                firstLink={info.firstLink}
                secondLink={info.secondLink}
                thirdLink={info.thirdLink}
                textFirstLink={info.textFirstLink}
                textSecondLink={info.textSecondLink}
                textThirdLink={info.textThirdLink}
                key={`question-${index}`}
              />
            );
          } else {
            return (
              <Question
                className="white"
                index={index}
                open={isOpen}
                setOpen={() => {
                  setOpen(isOpen ? null : index);
                }}
                title={info.title}
                text={info.text}
                secondText={info.secondText}
                thirdText={info.thirdText}
                firstLink={info.firstLink}
                secondLink={info.secondLink}
                thirdLink={info.thirdLink}
                textFirstLink={info.textFirstLink}
                textSecondLink={info.textSecondLink}
                textThirdLink={info.textThirdLink}
                key={`question-${index}`}
              />
            );
          }
        })}
      </div>
    </ContainerFaq>
  );
};

export default Faq;
