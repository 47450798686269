import styled from "styled-components";
// import { IoLogoWhatsapp } from "react-icons/io";
import whatsapp from "../img/whatsapp.svg";

const Whats = styled.div.attrs((props: { isMobile: boolean }) => ({
  isMobile: props.isMobile,
}))`
  width: 3.5rem;
  height: 3.5rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  transition: z-index 0.5s ease-in-out;
  z-index: ${(props) => (props.isMobile ? "-1" : "2")};

  .icone-whatsapp {
    width: auto;
    height: auto;

    .icone {
      width: 100%;
      height: 100%;
      color: #32b266;
      transition: color 0.3s ease-in-out;
      filter: grayscale(0.8);
      transition: grayscale, 0.3s ease-in-out;

      &:hover {
        filter: grayscale(0);
      }
    }
  }
`;

const WhatsAppFloat = (props: { isMobile: boolean }) => {
  return (
    <Whats isMobile={props.isMobile}>
      <a
        target="blank"
        className="icone-whatsapp"
        href="https://api.whatsapp.com/send?phone=555182819595&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20a%20respeito%20do%20*mobilParceiros*"
      >
        <img src={whatsapp} alt="icone whatsapp" className="icone" />
      </a>
    </Whats>
  );
};

export default WhatsAppFloat;
