import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: #002c5b;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .about {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: green; */

    .text-plus-image {
      display: flex;
      flex-direction: column;

      .img-about {
        width: 100%;
        min-width: 20rem;
        max-width: 40rem;
        align-self: center;

        img {
          width: 100%;
        }
      }

      .text {
        p {
          padding: 1rem 1.5rem;
          color: #fff;
        }
      }

      @media only screen and (min-width: 990px) {
        flex-direction: row;
        align-items: center;

        .img-about {
          flex: 1;
          padding: 1rem;
        }
        .text {
          padding: 1rem;
          flex: 1;
          font-size: 1.2rem;
          font-weight: 300;
        }
        .change-order-img {
          order: 2;
        }
        .change-order-text {
          order: 1;
        }
      }
    }
    .title {
      margin-top: 3rem;
      text-align: right;
      /* background-color: green; */
      img {
        max-width: 25rem;
        padding: 1rem;
      }

      hr {
        background-color: #32b266;
        opacity: 1;
        margin: 0 0 1rem 0;
        width: 50%;
        height: 0.3rem;
      }

      @media only screen and (min-width: 990px) {
        text-align: left;
        hr {
          width: 100%;
        }
      }
    }

    .hover-button-color {
      align-self: center;
      margin: 3rem 3rem;
      transition: background-color, color, 0.2s ease-in-out;
      &:hover,
      &:active {
        background-color: #fff;
        color: #002c5b;
      }
    }
  }
`;
