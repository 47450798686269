import styled from "styled-components";

export const ContainerFooter = styled.div`
  width: 100%;
  height: auto;
  background-image: linear-gradient(-45deg, #002c5b, #329e66);
  position: relative;

  .wave-footer {
    position: relative;
    top: -0.2rem;
    width: 100%;
    max-height: 24rem;
    transform: rotate(180deg);
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  .animation-location {
    top: -5%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 30%;
    max-width: 15rem;
    pointer-events: none;
  }

  .content {
    height: 100%;
    margin: 0 auto;
    /* background-color: red; */
    width: 100%;
    display: flex;
    flex-direction: column;

    .links {
      display: flex;
      flex-direction: column;
      padding-left: 2rem;

      a {
        color: #fff;
      }
    }

    hr {
      border: none;
      align-self: center;
      width: 80%;
      height: 0.1rem;
      background-color: #fff;
      opacity: 1;
    }

    .social-medias {
      /* background-color: red; */
      align-self: center;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 2rem;

      .icone-social-media {
        font-size: 2rem;
        color: #fff;
        /* background-color: red; */
      }
    }

    .powered {
      padding: 1rem 0;
      p {
        color: #fff;
        text-align: center;
        font-size: 0.8rem;
        font-weight: 400;

        span {
          color: #32b266;
        }
      }
    }

    @media only screen and (min-width: 990px) {
      .external-links {
        display: flex;
        margin-bottom: 1.5rem;
        flex-direction: column;
        /* background-color: yellow; */

        .links {
          font-size: 1.2rem;

          a {
            line-height: 2.5rem;
          }
        }
        .social-medias {
          display: flex;
          /* background-color: red; */
          align-self: center;
          margin-top: 0;
          max-width: 40%;

          .icone-social-media {
            font-size: 2.2rem;
          }
        }
      }
      .links a,
      .icone-social-media {
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`;
