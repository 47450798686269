import styled from "styled-components";
import { Link } from "react-scroll";

type Props = {
  isMobile: boolean;
  navBar: boolean;
};

export const Menu = styled.div.attrs((props: Props) => ({
  navBar: props.navBar,
}))`
  min-height: 5rem;
  margin: 0 auto;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 10vh;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background, box-shadow, 0.3s ease-in-out;
  background: ${(props) =>
    props.navBar
      ? "linear-gradient(120deg, #002c5b, #329e66 90%)"
      : "transparent"};
  box-shadow: ${(props) =>
    props.navBar ? "0 .2rem .4rem rgb(57 63 72 / 50%)" : "none"};

  .desktop-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-height: 550px) {
    background-image: linear-gradient(120deg, #002c5b, #329e66 90%);
  }

  @media only screen and (min-width: 990px) {
    .btn-menu-bg {
      display: none;
    }
  }
`;
export const Logo = styled(Link)`
  max-width: 25rem;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const BtnMenu = styled.div.attrs((props: Props) => ({
  isMobile: props.isMobile,
}))`
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5rem;
  height: ${(props) => (props.isMobile ? "0" : "0.3rem")};
  z-index: 5;
  background-color: #fff;
  transition: height 0.3s ease-in-out;
  border-radius: 1rem;

  &:after,
  &:before {
    border-radius: 1rem;
    background-color: #fff;
    transition: top, transform, 0.3s ease-in-out;
  }

  &:before {
    content: "";
    position: absolute;
    top: ${(props) => (props.isMobile ? "0" : "-.6rem")};
    height: 0.3rem;
    width: 2.5rem;
    transform: rotate(${(props) => (props.isMobile ? "45deg" : "0")});
  }
  &:after {
    content: "";
    position: absolute;
    top: ${(props) => (props.isMobile ? "0" : ".55rem")};
    height: 0.32rem;
    width: 2.5rem;
    transform: rotate(${(props) => (props.isMobile ? "-45deg" : "0")});
  }

  @media only screen and (min-width: 990px) {
    display: none;
  }
`;
