import custo from "../../img/slider/custo.svg";
import confianca from "../../img/slider/confianca.svg";
import personalizacao from "../../img/slider/personalizacao.svg";
import praticidadeVoce from "../../img/slider/praticidade-voce.svg";
import praticidadeCliente from "../../img/slider/praticidade-cliente.svg";
import suporte from "../../img/slider/suporte.svg";

export type CardType = {
  class: string;
  image: string;
  titulo: string;
  text?: string;
};

export const CardData: CardType[] = [
  {
    class: "custo",
    image: custo,
    titulo: "Baixo Investimento",
    text: "A mobilParceiros não cobra taxa de adesão e não determina prazo de fidelidade. Você paga apenas pelos seus clientes e rastreamentos ativos.",
  },
  {
    class: "personalizacao",
    image: personalizacao,
    titulo: "Personalização",
    text: "Permitimos a personalização do Portal Web e apps com a sua marca. Download do app de monitoramento diretamente na sua loja GooglePlay ou AppStore.",
  },
  {
    class: "praticidade",
    image: praticidadeVoce,
    titulo: "Praticidade para você",
    text: "As cobranças da sua Central de Rastreamento podem ser automatizadas e você pode monitorar os rastreamentos via aplicativo.",
  },
  {
    class: "praticidadecliente",
    image: praticidadeCliente,
    titulo: "Praticidade para seu cliente",
    text: "Seu cliente pode realizar o pagamentos das mensalidades sem burocracia, receber notificações via app ou Telegram, além de contar com uma plataforma simples de usar.",
  },
  {
    class: "confianca",
    image: confianca,
    titulo: "Confiabilidade",
    text: "Plataforma disponível desde 2013, com a qualidade Mobiltracker. 99,9% UPTIME, evolução contínua e ótimas avaliações do mercado.",
  },
  {
    class: "suporte",
    image: suporte,
    titulo: "Suporte",
    text: "Suporte especializado, com atendimento 100% humanizado, com equipe altamente qualificada. Dispomos de treinamento gratuito.",
  },
];
