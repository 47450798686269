import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

type Props = {
  isMobile: boolean;
};

export const BgBlack = styled.div.attrs((props: Props) => ({
  isMobile: props.isMobile,
}))`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: ${(props) => (props.isMobile ? "visible" : "hidden")};
  opacity: ${(props) => (props.isMobile ? 1 : 0)};
  z-index: 3;
  position: fixed;
  transition: opacity 1.3s linear;

  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

export const Mobile = styled.div.attrs((props: Props) => ({
  isMobile: props.isMobile,
}))`
  /* max-width: 24rem; */
  flex: 2;
  /* width: 100%; */
  height: 100%;
  background-color: #329e66;
  position: fixed;
  top: 0;
  right: ${(props) => (props.isMobile ? "0" : "-110%")};
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: right 0.8s ease-in-out;

  .position-icon-menu {
    position: absolute;
    right: 1.3rem;
    top: 1.7rem;

    @media only screen and (min-width: 990px) {
      display: none;
    }
  }
  ul {
    list-style: none;
  }

  .items {
    max-width: 28rem;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      height: 80%;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      li {
        width: 100%;
        text-align: left;
        font-weight: 100;

        a {
          font-size: 2rem;
          color: #fff;
        }
      }
    }
  }

  hr {
    border: none;
    background-color: #fff;
    opacity: 1;
    width: 2.5rem;
    height: 0.2rem;
    position: absolute;
    top: 45%;
    left: 3.5rem;
    transform: translate(-50%);

    @media only screen and (min-width: 990px) {
      display: none;
    }
  }

  .social {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login {
      flex: 1;
      padding-top: 1rem;
      padding-left: 2rem;
      width: 100%;
      max-width: 28rem;
      /* background-color: red; */
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;

      a {
        font-size: 1.4rem;
        letter-spacing: 0.1rem;
        width: 100%;
        color: #fff;
      }
    }

    .icons {
      flex: 2;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 1.2rem;
      width: 100%;

      ul {
        display: flex;
        width: 100%;
        height: 20%;

        li {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          .single-icon {
            font-size: 2rem;
            color: #fff;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 990px) {
    max-width: 85rem;
    width: 100%;
    height: 100%;
    position: initial;
    background-color: transparent;
    margin-right: 1.5rem;
    flex-direction: row;

    .items {
      height: 100%;

      ul {
        flex-direction: row;
        width: 100%;
        height: 100%;

        li {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            font-size: 1.3rem;
            text-align: center;
            opacity: 0.7;
            transition: opacity 0.2s ease-in-out;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .social {
      justify-content: center;
      align-items: center;

      .icons {
        display: none;
      }
      .login {
        padding-top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: nowrap;
        flex: 2;

        a {
          font-size: 1rem;
          max-width: 10rem;
          border-radius: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80%;
          font-weight: 700;
          background-color: #fff;
          width: 100%;
          color: #002c5b;
          transition: bakcground-color, color 0.2s ease-in-out;

          &:hover {
            background-color: #002c5b;
            color: #fff;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    width: 50%;
  }
`;

export const NavLinks = styled(LinkS)`
  position: relative;
  cursor: pointer;

  &:hover {
    &::before,
    &::after {
      width: 50%;
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 0%;
    height: 0.15rem;
    bottom: -0.2rem;
    background-color: #fff;
    transition: width 0.5s ease-in-out;
  }
  &::after {
    right: 50%;
  }
  &::before {
    left: 50%;
  }
`;
