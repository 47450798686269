import styled from "styled-components";
import { IoIosArrowUp } from "react-icons/io";

export type QuestionProps = {
  title: string;
  text: string;
  secondText?: string;
  thirdText?: string;
  firstLink?: string;
  secondLink?: string;
  thirdLink?: string;
  textFirstLink?: string;
  textSecondLink?: string;
  textThirdLink?: string;
  open: boolean;
  index: number;
  className?: string;
  setOpen: () => void;
};

const SingleQuestion = styled.div.attrs((props: QuestionProps) => ({
  open: props.open,
  index: props.index,
}))`
  color: #002c5b;
  width: 100%;
  transition: transform, 0.2s ease-in-out;

  &:hover {
    transform: scaleX(1.02);
  }

  label {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color, color, 0.2s ease-in-out;

    &:hover {
      background-color: #002c5b;
      color: #fff;
    }

    .title-question {
      font-weight: 400;
      font-size: 1.2rem;
      /* background-color: red; */
      /* margin-right: 1rem; */
      padding: 1rem 1rem 1rem 1.4rem;
    }

    .icon-arrow-faq {
      color: #329e66;
      font-size: 1.2rem;
      /* background-color: red; */
      position: absolute;
      left: 0.5rem;
      /* margin-right: 1rem; */
      transition: all 0.2s ease-in-out;
    }
  }

  .gray {
    background-color: ${(props) =>
      props.open ? `${props.theme.azul}` : "#e4e4e4"};
    color: ${(props) => (props.open ? "#fff" : `${props.theme.azul}`)};
  }
  .white {
    background-color: ${(props) =>
      props.open ? `${props.theme.azul}` : "#fff"};
    color: ${(props) => (props.open ? "#fff" : `${props.theme.azul}`)};
  }
  .text-question {
    display: ${(props) => (props.open ? "initial" : "none")};

    p {
      position: relative;
      font-size: 1rem;
      padding: 1rem 0;
    }
  }

  @media only screen and (min-width: 990px) {
    .title-question {
      font-size: 1.2rem;
    }
    .text-question {
      font-size: 1rem;
    }
  }
`;

const Question = (props: QuestionProps) => {
  return (
    <SingleQuestion open={props.open}>
      <label className={props.className} htmlFor={props.title}>
        <input
          id={props.title}
          type="checkbox"
          defaultChecked={props.open}
          onChange={() => {
            // setChecked(!checked);
            props.setOpen();
          }}
          style={{ visibility: "hidden" }}
        />
        <IoIosArrowUp
          className="icon-arrow-faq"
          style={{ transform: `rotate(${props.open ? "0deg" : "180deg"})` }}
        />
        <h3 className="title-question">{props.title}</h3>
      </label>
      <div className="text-question">
        <p>
          {props.text}
          <a href={props.firstLink}>{props.textFirstLink}</a>
          {props.secondText}
          <a href={props.secondLink}>{props.textSecondLink}</a>
          {props.thirdText}
          <a href={props.thirdLink}>{props.textThirdLink}</a>
        </p>
      </div>
    </SingleQuestion>
  );
};

export default Question;
