export const DataFaq = [
  {
    title:
      "Por que as pessoas vão pagar mensalmente para mim se existe uma versão gratuita do Mobiltracker?",
    text: "Os planos de parceiros possuem mais funcionalidade que a versão gratuita. Além disso, você é um facilitador. Você faz pelos seus clientes tudo aquilo que eles não estão interessados ou preparados para fazer, como configuração de rastreador, acompanhamento do veículo, controle de alertas. Essas atividades qualificam a prestação do seu serviço e justifica um custo maior para o cliente, o que gera uma maior rentabilidade para você.",
  },
  {
    title: "Vocês vendem os rastreadores?",
    text: "Nós não vendemos os aparelhos rastreadores. Você precisa ter seu próprio fornecedor para iniciar seu negócio. Se não possui um, podemos indicar algumas opções de mercado para você. Possuímos a solução de software para rastreadores do tipo 'sem mensalidade' como por exemplo os da linha TK102, TK103, TLT e similares.",
  },
  {
    title: "Existe previsão para suportar outros tipos de rastreadores?",
    text: "Sim. Estamos constantemente analisando novos modelos e em contato com novos fabricantes para permitir o maior número possível de rastreadores em nosso sistema.",
  },
  {
    title:
      "Meus clientes terão uma senha de acesso para consultar a localização de seus rastreadores ou apenas eu?",
    text: "Sim! Seus clientes terão dados de acesso, se você desejar. Para cada cliente adicionado é necessário adicionar e-mail e senha válidos. Porém, se preferir gerenciar completamente sem dar acesso direto aos clientes, também é possível.",
  },
  {
    title: "A minha central de rastreamento é customizável? Como?",
    text: "Caso opte por registar um domínio e ter o acesso da central de rastreamento em seu próprio site, é possível customizá-la com a sua marca. Para isso, envie um e-mail para o Suporte Técnico solicitando o serviço. Obs.: Alguns pré-requisitos devem ser observados antes da contratação do serviço.",
  },
  {
    title: "Como administro meus clientes?",
    text: "Através do portal do parceiro ",
    secondText: ". Acesse o vídeo para entender como funciona: ",
    firstLink: "https://portalparceiros.mobiltracker.com.br",
    secondLink: "https://goo.gl/QBuhgJ",
    textFirstLink: "mobilParceiros",
    textSecondLink: "Montando sua Central de Rastreamento.",
  },
  {
    title: "Como meus clientes monitoram seus rastreadores?",
    text: "Seus clientes podem monitorar o rastreamento através do computador acessando a ",
    secondText: "ou aplicativos de monitoramento ",
    thirdText: "e ",
    firstLink: "https://plataformatk.com.br",
    secondLink: "https://goo.gl/fxEQ65",
    thirdLink: "https://goo.gl/zZb53y",
    textFirstLink: "Plataforma TK ",
    textSecondLink: "app Android ",
    textThirdLink: "app iOS",
  },
  {
    title: "Qual o investimento necessário para adquirir a solução?",
    text: "O valor mínimo mensal a ser emitido é de R$49,00. O valor total da sua fatura mensal vai variar de acordo com a quantidade de planos selecionados e vinculados aos seus clientes. Se o valor de R$49,00 for ultrapassado, só será cobrado o valor dos planos vinculados aos seus clientes. Você pode obter uma explicação mais detalhada aqui. Ou entre em contato com nossos atendentes.",
  },
];
