import styled from "styled-components";
import { CardData } from "./CardData";

const ContainerCard = styled.div`
  margin: 3rem auto;
  height: auto;
  width: 100%;
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  :nth-child(odd) {
    /* background-color: red; */
  }
  :nth-child(even) {
    /* background-color: blue; */
  }
  width: 100%;
  height: 100%;
  max-height: 25rem;
  min-height: 25rem;
  max-width: 20rem;
  margin: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: inset 0.1rem 0.1rem 3rem #fafafadc,
    inset -0.1rem -0.1rem 0.3rem #dadada;

  transition: box-shadow 0.2s ease-in-out;

  .img-card {
    min-height: 10rem;
    margin-top: 0.5rem;
    /* background-color: yellow; */
    width: 50%;
    display: flex;

    img {
      width: 100%;
    }
  }
  .text {
    flex: 2;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h2 {
      flex: 1;
      max-height: 2rem;
      font-size: 1.4rem;
      color: #002c5b;
      margin: 1rem 0;
    }
    p {
      font-weight: 300;
      padding: 0 1rem;
      line-height: 1.5rem;
      flex: 1;
      font-size: 1rem;
    }
  }
`;

const Advantage = () => {
  return (
    <ContainerCard className="w-1000">
      {CardData.map((data, index) => {
        return (
          <Card id="good" key={index}>
            <div className={`img-card ${data.class}`}>
              <img src={data.image} alt={data.class} />
            </div>
            <div className="text">
              <h2>{data.titulo}</h2>
              <p>{data.text}</p>
            </div>
          </Card>
        );
      })}
    </ContainerCard>
  );
};

export default Advantage;
