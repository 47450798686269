import { ContainerHeader, Wave, ContentText } from "./style/Header";
import wave from "../img/wave3-01.svg";
import CallToAction from "./CallToAction";
import main from "./animations/main-animation.json";
import arrow from "./animations/arrow-down.json";
import Lottie from "react-lottie";
import { Link } from "react-scroll";

const Header = () => {
  const arrowDown = {
    loop: true,
    autoplay: true,
    animationData: arrow,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const mainAnimation = {
    loop: true,
    autoplay: true,
    animationData: main,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <ContainerHeader id="home">
        <ContentText className="w-1000">
          <div className="animation-main">
            <Lottie options={mainAnimation} />
          </div>
          <div className="containerTextBoxP">
            <div className="textBoxP">
              <h1>Quer ter mais tranquilidade</h1>
              <h1>na hora de monitorar</h1>
              <h1>a sua central de rastreamento?</h1>
              <p className="small">Nós podemos lhe oferecer</p>
              <p className="small">a tranquilidade que você merece</p>
            </div>
            <div className="btn">
              <CallToAction
                className="btn-header"
                text="Comece Já"
                link="https://sejaparceiro.mobiltracker.com.br/"
              />
            </div>
          </div>{" "}
        </ContentText>
        <Wave>
          <img
            className="img-hidden"
            src={wave}
            alt="animação flecha pra baixo"
          />
          <Link to="good" className="animation-arrow">
            <Lottie
              options={arrowDown}
              height={"100%"}
              width={"100%"}
              style={{ pointerEvents: "none" }}
            />
          </Link>
        </Wave>
      </ContainerHeader>
    </>
  );
};

export default Header;
