import styled from "styled-components";
type CallToActionProps = {
  text: string;
  className?: string;
  link?: string;
};
const Btn = styled.a`
  background-color: #32b266;
  width: 15rem;
  height: 3rem;
  font-size: 1.2rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  transition: background-color, color, 0.2s ease-in-out;

  &:hover,
  &:active {
    background-color: #002c5b;
    color: #fff;
  }

  @media only screen and (min-width: 990px) {
    height: 3.5rem;
    width: 20rem;
    font-size: 1.5rem;
  }
`;

const CallToAction = (props: CallToActionProps) => {
  return (
    <Btn className={props.className} target="blank" href={props.link}>
      {props.text}
    </Btn>
  );
};

export default CallToAction;
