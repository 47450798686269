import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider as Theme } from "styled-components";

const colors = {
  azul: "#002C5B",
  verde: "#329E66",
  verdeClaro: "#32B266",
};

ReactDOM.render(
  <React.StrictMode>
    <Theme theme={colors}>
      <App />
    </Theme>
  </React.StrictMode>,
  document.getElementById("root")
);
