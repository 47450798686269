import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
  
  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-family: 'Roboto', sans-serif;
  }
  body {
    background-color: #fff;
    ul {
        list-style: none;
      }
      a {
        text-decoration: none;
      }
  }

  .w-1000 {
    max-width: 85rem;
  }  

  .icon-menu {
    font-size: 1.8rem;
    color: #fff;
  }
  
  p, h1, h2, h3, ul {
    margin: 0;
    padding: 0;
  }
  
`;

export default GlobalStyle;
